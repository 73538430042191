.dark{
  --card-text-color: var(--black);
  --card-bg-color: var(--ice);
}

.light{
  --card-text-color: var(--black);
  --card-bg-color: var(--white);
}

.container {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  border: 1px solid var(--b-green);
  border-radius: var(--radius-s);
  transition: border 0.3s ease-in-out;
  height: 100%;
}

.container:hover{
  border: 1px solid var(--blue);
}

.image{
  position: relative;
  height: 300px;
}

.info{
  display: flex;
  flex-flow: column wrap;
  color: var(--card-text-color);
  padding: var(--spacing-64) var(--spacing-40);
  gap: var(--spacing-64);
  flex: 1;
  border-radius: 0 0 var(--radius-m) var(--radius-m);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
}
.dark .info{
  color: var(--card-text-color);
  background: var(--card-bg-color);
}

.container:hover .info{
  color: var(--ice);
  background: var(--b-green);
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 0 0 var(--radius-m) var(--radius-m);
}

.content{
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-8);
  justify-content: flex-start;
}
.city{
  font: var(--font-h3-mobile-default);
  font-family: var(--archivo-font);
  font-weight: 900;
}

.address{
  font: var(--font-p1-desktop-default);
}

.price {
  font: var(--font-h3-desktop-default);
  text-transform: uppercase;
}

.tag {
  position: absolute;
  top: var(--spacing-8);
  right: var(--spacing-8);
  display: flex;
  flex-flow: column nowrap;
  gap: var(--spacing-8);
  align-items: flex-end;
}

.tag > * {
  width: fit-content;
}

.search.container{
  border-radius: var(--radius-m);
}

.search.container .image{
  height: 220px;
}
.search.container img{
  border-radius:  var(--radius-m) var(--radius-m) 0 0;
}

.search .content{
  gap: var(--spacing-12);
}

.search .tag > div{
  background: var(--ice) !important;
  color: var(--b-green) !important;
  border: 1px solid var(--b-green);
  font: var(--font-p2-desktop-default);
  padding: var(--spacing-8) var(--spacing-12);
}

@media (max-width: 1024px){
  .city{
    font: var(--font-p1-mobile-default);
    font-family: var(--archivo-font);
    font-weight: 900
  }
  .address{
    font: var(--font-p2-mobile-default);
  }
  .price{
    font: var(--font-h3-mobile-default);
  }
  .image{
    height: 240px;
  }

  .content{
    padding: 0;
  }
}

@media (max-width: 480px){
  .info{
    gap: var(--spacing-40);
    padding: var(--spacing-24) var(--spacing-16) var(--spacing-40) var(--spacing-16) !important;
  }
}