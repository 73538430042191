.light{
  --text-color: var(--black);
  --bg-color: var(--white);
}

.dark{
  --text-color: var(--black);
  --bg-color: var(--ice);
}

.wrapper{
  background: var(--bg-color);
}

.container {
  --padding-container: var(--spacing-80);
  display: flex;
  flex-flow: column wrap;
  gap: var(--spacing-80);
  background: var(--bg-color);
  color: var(--text-color);
  padding: var(--padding-container);
}

.title{
  padding: 0 !important;
}

.link{
  display: flex;
  align-self: flex-end;
  padding: var(--spacing-40);
}

.carousel .card:first-child{
  border-radius: var(--radius-l) 0 0 var(--radius-l);
}
.carousel .card:first-child img{
  border-radius: var(--radius-l) 0 0 0;
}
.carousel .card:first-child :global(.card-residence_info) {
  border-radius:  0 0 0 var(--radius-l);
}

.carousel .card:nth-child(2){
  border-radius: 0;
}
.carousel .card:nth-child(2) img{
  border-radius: 0;
}
.carousel .card:nth-child(2) :global(.card-residence_info) {
  border-radius: 0;
}

.carousel .card:nth-child(3){
  border-radius: 0 var(--radius-l) var(--radius-l) 0;
}
.carousel .card:nth-child(3) img{
  border-radius: 0 var(--radius-l) 0 0;
}
.carousel .card:nth-child(3) :global(.card-residence_info) {
  border-radius:  0 0 var(--radius-l) 0;
}


@media (max-width: 1200px) {
  .container{
    padding: var(--padding-container) 0;
  }

  .title{
    padding: 0 var(--spacing-80) !important;
  }

  .link{
    padding: var(--spacing-40) var(--spacing-80);
  }

  .carousel .card {
    border-radius: var(--radius-l) !important;
  }
  .carousel .card img{
    border-radius: var(--radius-l) var(--radius-l) 0 0 !important;
  }
  .carousel .card :global(.card-residence_info){
    border-radius:  0 0 var(--radius-l) var(--radius-l) !important;
  }
}

@media (max-width: 1024px) {
  .container{
    --padding-container: var(--spacing-64) 0;
    padding: var(--padding-container);
    gap: var(--spacing-24);
  }

  .link{
    padding: var(--spacing-24) var(--spacing-24);
  }

  .title{
    padding: 0 var(--spacing-24) !important;
  }
}

@media (max-width: 480px) {
  .title{
    padding: 0 var(--spacing-16) !important;
  }
}